<template>
  <div class="pa-0 ma-0">
    <complaints v-if="address" :current_req="null" :complaintsFiltered="complaintsFiltered"></complaints>
  </div>
</template>

<script>

import { mapGetters, mapActions, mapState } from "vuex";
import complaints from "@/components/request/complaintTab";

export default {
  name: "Complaint",
  components: {
    complaints,
  },
  data() {
    return {
      complaintFilters:{},
    };
  },
   async created() {
    Object.assign(this.complaintFilters, this.storeComplaintFilters);
    await this.filterComplaints(this.complaintFilters);
    await this.clearComplaintsFilter();
    await this.clearAddressesFilter();
    await this.retrieveAddresses();
    await this.retriveComplaints();
    await this.retriveRequests();
  },
  computed: {
     ...mapGetters('complaint', {
      'complaintsFiltered':'complaintsFiltered'
    }),
     ...mapState('complaint', {
      'storeComplaintFilters':'filter'
    }),

    ...mapState("address", {
      address: "addresses"
    }),
  },

  watch:{
    complaintsFiltered: {
      handler(){
        Object.assign(this.complaintFilters, this.storeComplaintFilters);
      }, deep: true 
    }
   },
  methods: {
     ...mapActions('address', ['retrieveAddresses','clearAddressesFilter']),
    ...mapActions('complaint',['retriveComplaints','filterComplaints','clearComplaintsFilter']),
    ...mapActions("request", ["setRequestItem", "retriveRequestItem","retriveRequests"]),
  }
};
</script>