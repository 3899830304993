<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
    <div class=" my-border">  
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{tableTitle}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{complaintsFiltered?complaintsFiltered.length:'0'}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="openAdd()" v-if="$checkPermission('Complaints.Create')">{{$t("New Complaint")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
    <!-- dialog add complaint -->
      <v-dialog v-model="dialog" max-width="900px">
      <v-card class="customoffwhite">
        <v-card-title class="justify-center ma-0 customlightgray">
          <span class="font-weight-light">{{
            formTitle
          }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                 <v-autocomplete
                    clearable
                    v-model="cu_req.id"
                    :items="requests.map(request=>{return {value:request.id,text:request.reqCode}})"
                    :label="$t('requestid')"
                    :placeholder="$t('requestid')"
                    dense
                    outlined
                    required
                    ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu  :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      v-model="activeComplaint.dateReceived"
                      v-on="on"
                      :label="$t('Date Received')"
                      :placeholder="$t('Date Received')"
                    ></v-text-field>
                  </template>
                  <v-date-picker dark :max="nowDate"
                    v-model="activeComplaint.dateReceived"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="address" cols="12" sm="6" md="6">
                <v-text-field
                  :label="$t('commissionerFileNumber')"
                  :placeholder="$t('commissionerFileNumber')"
                  v-model="activeComplaint.commissionerFileNumber"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="activeComplaint.complainant"
                  :items="address.map(user=>{return {value:user.id,text:user.FirstName+' '+user.LastName}})"
                  :label="$t('Complainant')"
                  :placeholder="$t('Complainant')"
                  dense
                  outlined
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-autocomplete
                  v-model="activeComplaint.complaintReason"
                  :items="getLookup('CmpReason')"
                  dense
                  outlined
                  :label="$t('Complaint Reason')"
                  :placeholder="$t('Complaint Reason')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                  <v-textarea
                  grow
                  outlined
                  dense
                  v-model="activeComplaint.complaintText"
                  :label="$t('Complaint Text')"
                  :placeholder="$t('Complaint Text')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu  :close-on-content-click="false" 
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      v-model="activeComplaint.dateOfFinding"
                      v-on="on"
                      :label="$t('Date Of Finding')"
                      :placeholder="$t('Date Of Finding')"
                    ></v-text-field>
                  </template>
                  <v-date-picker dark
                    v-model="activeComplaint.dateOfFinding"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
              <v-text-field
                  :label="$t('Investigator')"
                  :placeholder="$t('Investigator')"
                  v-model="activeComplaint.investigator"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                  v-model="activeComplaint.complaintFinding"
                  :items="getLookup('CmpFinding')"
                  dense
                  outlined
                  :label="$t('Complaint Finding')"
                  :placeholder="$t('Complaint Finding')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                  v-model="activeComplaint.complaintResult"
                  :items="getLookup('CmpResult')"
                  dense
                  outlined
                  :label="$t('Complaint Result')"
                  :placeholder="$t('Complaint Result')"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="custom-color-accent-text" text @click="close">{{
            $t("cancel")
          }}</v-btn>
          <v-btn class="custom-color-accent-text" :disabled="isDisable" text @click="saveComplaint">{{
            $t("save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- //dialog add complaint -->
    <!-- dialog advanced search -->
     <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
                <v-col cols="12" class="ma-0 py-0">
                  <v-text-field class="ma-0" :label="$t('commissionerFileNumber')" :placeholder="$t('commissionerFileNumber')" dense outlined v-model="filters.commissionerFileNumber"></v-text-field>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    :label="$t('Complaint Reason')"
                    :placeholder="$t('Complaint Reason')"
                    small-chips
                    multiple
                    dense
                    clearable
                    outlined
                    v-model="filters.complaintReason"
                    :items="getLookup('CmpReason')"
                    autowidth
                    return-object
                  >
                  </v-autocomplete>
                </v-col> 
                <v-col cols="12" class="ma-0 py-0">
                    <v-text-field class="ma-0" v-model="filters.complaintText" :label="$t('Complaint Text')" outlined dense :placeholder="$t('Complaint Text')"></v-text-field>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    small-chips
                    multiple
                    clearable
                    outlined
                    v-model="filters.requestId"
                    :items="requests.map(request=>{return {value:request.id,text:request.reqCode}})"
                    :label="$t('requestid')"
                    :placeholder="$t('requestid')"
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="address" cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    multiple
                    small-chips
                    clearable
                    outlined
                    v-model="filters.complainant"
                    :items="address.map(user=>{return {value:user.id,text:user.FirstName+' '+user.LastName}})"
                    :label="$t('Complainant')"
                    :placeholder="$t('Complainant')"
                    dense
                   return-object
                  ></v-autocomplete>
                </v-col> 
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    multiple
                    clearable
                    outlined
                    small-chips
                    v-model="filters.complaintFinding"
                    :items="getLookup('CmpFinding')"
                    :label="$t('Complaint Finding')"
                    :placeholder="$t('Complaint Finding')"
                    dense
                   return-object
                  ></v-autocomplete>
                </v-col> 
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    multiple
                    clearable
                    outlined
                    small-chips
                    v-model="filters.complaintResult"
                    :items="getLookup('CmpResult')"
                    :label="$t('Complaint Result')"
                    :placeholder="$t('Complaint Result')"
                    dense
                   return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dateReceived"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('receiveddaterange')"
                        :placeholder="$t('receiveddaterange')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dateReceived" :max="nowDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dateOfFinding"
                        multiple
                        chips
                        small-chips
                        dense
                        :label="$t('datoffindingrange')"
                        :placeholder="$t('datoffindingrange')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dateOfFinding"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dateClosed"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('closeddaterange')"
                        :placeholder="$t('closeddaterange')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dateClosed"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="findComplaints()">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- /dialog advanced search -->
    <!-- Filters -->    
    <v-divider  v-show="filterList.length>0"></v-divider>       
    <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
            <v-col class="pa-0 ma-0" cols="12">
              <v-scroll-x-transition group hide-on-leave>
              <v-tooltip v-for="(f, i) in filterList" :key="i" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip  v-on='on' v-bind="attrs"
                    class="ma-1"
                    color="custom-color-accent"
                    close
                    small
                    close-icon="mdi-delete"
                    @click:close="removeFilter(f)" >
                      {{f}}
                    </v-chip>
                  </template>
                  <span>{{$t("removefilter")}}</span>
                </v-tooltip>
              </v-scroll-x-transition>
            </v-col>
    </v-row> 
    <!-- /Filters -->
    <v-divider></v-divider>
    <v-data-table :mobile-breakpoint="1081" 
      :headers="headers"
      :items="complaintsFiltered"
      sort-by="id"
      class="elevation-0 text--secondary customoffwhite pa-0 ma-0"
      :search="search"
      :loading="loading"
      :loading-text="$t('loading')"
      expanded.sync
      show-expand
      :footer-props="{
        'items-per-page-text': $t('rowsperpage'),
      }"
    >
      <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>
      <template v-slot:[`item.updated_at`]="{item}">{{ item.updated_at | formatDate }}</template>
      <template v-slot:[`item.ATIPOfficerId`]="{item}"><span class="text-capitalize">{{ getUser(item.ATIPOfficerId) }}</span></template>
      <template v-slot:[`item.complainant`]="{item}"><span class="text-capitalize">{{ getAddress(item.complainant) }}</span></template>
      <template v-slot:[`item.dateReceived`]="{item}">{{ item.dateReceived | formatDate }}</template>
      <template v-slot:[`item.dateOfFinding`]="{item}">{{ item.dateOfFinding | formatDate }}</template>
      <template v-slot:[`item.dateClosed`]="{item}">{{ item.dateClosed | formatDate }}</template>
      <template v-slot:expanded-item="{ headers, item }" elevation="0">
        <td class=" my-side-border" :colspan="headers.length" elevation="0">
          <span class="font-weight-bold text--secondary"> {{$t("Complaint Text")}}: </span
          ><span class="text--secondary">{{ item.complaintText }}</span>
        </td>
      </template>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
        <v-row class="ma-0 pa-0 text-right">
          <v-col cols="12" class="ma-0 pa-0">
            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon v-if="$checkPermission('Complaints.Update')">
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
              <v-btn :disabled="item.dateClosed!=null || !$checkPermission('Complaints.Close')" icon @click.stop="closeComplaint(item)">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on='on' v-bind="attrs">mdi-clipboard-check-outline</v-icon>
                  </template>
                  <span>{{$t("Complaintclose")}}</span>
                </v-tooltip>
              </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    </div>
    <complaintbottomsheet v-show="sheet" v-if="closeComp!={}" :complaint="closeComp" :sheet="sheet" @setSheet="setSheet"></complaintbottomsheet>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import complaintbottomsheet from '@/components/request/ComplaintBottomSheet'

export default {
  props: ["current_req","complaintsFiltered"],
  components: {
    complaintbottomsheet
  },
  data() {
    return {
      nowDate: new moment().toISOString(),
      cu_req:{},
      filterList:[],
      dialog: false,
      sheet: false,
      closeComp:{},
      editedIndex: -1,
      activeComplaint: {},
      search: "",
      dialog_adv: false,
      headers: [
        {
          text: this.$t("commissionerFileNumber"),
          value: "commissionerFileNumber",
        },
        {
          text: this.$t("ATIPOfficerId"),
          value: "ATIPOfficerId",
        },
        {
          text: this.$t("Complainant"),
          value: "complainant",
        },
        {
          text: this.$t("Date Received"),
          value: "dateReceived",
        },
        {
          text: this.$t("Date Closed"),
          value: "dateClosed",
        },
        {
          text: this.$t("Date Of Finding"),
          value: "dateOfFinding",
        },
        { text: "", value: "action", sortable: false },
      ],
      filters: {
        commissionerFileNumber: "",
        complaintReason:[],
        complaintText:"",
        dateReceived:[],
        dateClosed:[],
        dateOfFinding:[],
        complainant:[],
        requestId:[],
        complaintResult:[],
        complaintFinding:[],
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? this.$t("New Complaint")
        : this.$t("Edit Complaint");
    },
    tableTitle() {
      return this.$t("Complaints");
    },
    ...mapState("configuration", {
      configurations: "configurations",
      loading: "loading",
      requestConfiguration: "requestConfiguration",
    }),
    isDisable() {

      return (
        !this.activeComplaint.dateReceived ||
        !this.activeComplaint.commissionerFileNumber ||
        !this.activeComplaint.complainant ||
        !this.activeComplaint.complaintReason ||
        !this.activeComplaint.complaintText
      );
    },
    ...mapState("request", {
      requests: "requests"
    }),
    ...mapState("address", {
      address: "addresses"
    }),
    ...mapState({
      user: "user"
    }),
    ...mapState({
      users: "users"
    }),
    ...mapState("complaint", {
      loading: "loading",storeFilters:"filter"
    }),
  },

  async created() {
        Object.assign(this.filters, this.storeFilters);
        Object.assign(this.cu_req, this.current_req);
        this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
        this.filterList = this.filterList.filter(el=> el != "");
        await this.clearAddressesFilter();
        await this.retrieveAddresses();
        await this.retriveComplaints();
    },
  watch:{
    storeFilters: {
      handler(){
          Object.assign(this.filters, this.storeFilters);  
          this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
                this.filterList = this.filterList.filter(el=> el != "");

      }, deep: true 
    },
  },
  methods: {
    getUser(id){
      return this.users.filter(i => i.id === id ).map(j=>j.first_name+' '+j.last_name)[0]
    },
    getAddress(id){
      return this.address.filter(i => i.id === id ).map(j=>j.FirstName+' '+j.LastName)[0]
    },
    closeComplaint(item){
      this.closeComp=item;
      this.setSheet(true);
    },
    setSheet(sheet){
      this.sheet=sheet;
    },
    findComplaints(){
      this.close();
      this.filterComplaints(this.filters);
    },
    removeFilter(item){

          Object.keys(this.filters).forEach((key)=>{
          if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
           this.filters[key]={text:"",value:""};        
          else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
           this.filters[key]="";           

          else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
          this.filters[key]= this.filters[key].filter(a=>a.text!=item);

         else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
          this.filters[key]= this.filters[key].filter(a=>a!=item);
          

          });
          this.filterComplaints(this.filters);
        
      }, 
    getLookup(column) {
      return this.configurations
        .filter((filter) => filter.LookUpName === column)
        .map((item) => {
          let arr = [];
          arr["text"] =
            this.$vuetify.lang.current === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
    async saveComplaint() {
      if (this.editedIndex == -1) {
        this.activeComplaint.request_id = this.cu_req.id;
        this.activeComplaint.ATIPOfficerId = this.user.id;
        this.createComplaint(this.activeComplaint).then(() => {
          let color="success";
          let alert="itemAddedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        });

      } else {
        this.activeComplaint.request_id = this.cu_req.id;
        this.activeComplaint.ATIPOfficerId = this.user.id;
        this.updateComplaint(this.activeComplaint).then(() => {
          let color="dark";
          let alert="itemUpdatedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        });
      }
      this.close();
    },
    close() {
      this.dialog = false;
      this.dialog_adv = false;
      this.editedIndex = -1;
      this.activeComplaint = {};
      this.cu_req={}
      Object.assign(this.cu_req, this.current_req);
    },
    openAdd() {
      this.dialog = true;
      this.editedIndex = -1;
      this.activeComplaint = {};
      this.cu_req={}
      Object.assign(this.cu_req, this.current_req);
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.complaintsFiltered.indexOf(item);
      this.activeComplaint = {};
      Object.assign(this.activeComplaint,item);
      this.cu_req.id = this.activeComplaint.request_id;
    },
    ...mapActions("complaint", ["createComplaint","filterComplaints", "updateComplaint","retriveComplaints"]),
    ...mapActions("request", ["addReqComplaint", "updateReqComplaint"]),
    ...mapActions('address', ['retrieveAddresses','clearAddressesFilter']),
  },
};
</script>

<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}

</style>