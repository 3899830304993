<template>
  <div v-if="editedComplaint" class="text-right">
    <v-bottom-sheet v-model="localsheet">
      <v-sheet
        class="white pa-4 ma-0"
      >
        <v-row class="pa-0 ma-0"> 
          <v-col cols="12" sm="6" class="text-center text-sm-left ma-auto px-0">
            {{ $t('Closing Information') }} 
          </v-col>
          <v-col cols="12" sm="6" class="text-center text-sm-right ma-auto  px-0">
            <v-btn class="pa-auto ma-auto mr-2" @click="setSheet(false)" depressed>{{$t("cancel")}}</v-btn>
            <v-btn class="pa-auto ma-auto custom-color-accent" @click="closeComplaint" depressed>{{$t("close")}}</v-btn>
          </v-col>
        </v-row>
       
        <div class="customlightgray pa-3 ma-0 my-border">
          <v-row class="pa-0 ma-0 sheet">
            <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :label="$t('commissionerFileNumber')"
                  :placeholder="$t('commissionerFileNumber')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :value="editedComplaint.commissionerFileNumber"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col> 
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  :label="$t('Date Closed')"
                  :placeholder="$t('Date Closed')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :value="getToday()"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-menu  :close-on-content-click="false" 
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      v-model="editedComplaint.dateOfFinding"
                      v-on="on"
                      :label="$t('Date Of Finding')"
                      :placeholder="$t('Date Of Finding')"
                    ></v-text-field>
                  </template>
                  <v-date-picker dark
                    v-model="editedComplaint.dateOfFinding"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
              <v-text-field
                  :label="$t('Investigator')"
                  :placeholder="$t('Investigator')"
                  v-model="editedComplaint.investigator"
                  dense
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                  v-model="editedComplaint.complaintFinding"
                  :items="getLookup('CmpFinding')"
                  dense
                  outlined
                  :label="$t('Complaint Finding')"
                  :placeholder="$t('Complaint Finding')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                  v-model="editedComplaint.complaintResult"
                  :items="getLookup('CmpResult')"
                  dense
                  outlined
                  :label="$t('Complaint Result')"
                  :placeholder="$t('Complaint Result')"
                ></v-autocomplete>
              </v-col>



          </v-row>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import moment from "moment";

  export default {
    props: ["complaint","sheet"],
      data() {
        return {
          editedComplaint:{},
          localsheet:false,
        }
      },
     async created(){
       await Object.assign(this.editedComplaint, this.complaint);
       // console.log(this.editedComplaint);
        this.localsheet=this.sheet;
      },

     watch:{
       complaint(val){
         Object.assign(this.editedComplaint, val);
       }, 
       sheet(val){
         this.localsheet=val;
       },
       localsheet(val){
          this.setSheet(val)
       }
      },
      computed: {
        ...mapState("configuration", {
          configurations: "configurations"
        }),
      },
    methods:{ 
      getToday(){
        return  moment().format("YYYY-MM-DD");
      },
       getlookupvalue(lookupname, val) {
          return this.configurations.filter(filter => filter.LookUpName === lookupname && filter.LookUpKey == val).map(item => {
            let status = "";
            status = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
            return status;
          })[0]
        },
        getLookup(column) {
          return this.configurations.filter(filter => filter.LookUpName === column).map(item => {
            let arr = [];
            arr['text'] = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
            arr['value'] = item.LookUpKey;
            return arr;
          })
        },
        setSheet(sheet){
          this.$emit('setSheet',sheet);
        },
        setEditedClosing(){
          this.editedComplaint.dateClosed= this.getToday();
        },
        closeComplaint(){
          this.setEditedClosing();
          this.updateComplaint(this.editedComplaint).then(() => {
            this.setSheet(false);
            let item = {text:this.$t("complaintclosedalert"), type:"dark", alert:true};
            this.$root.$emit("callAlert", item);
            this.retriveComplaints();
          })
        },
        ...mapActions("complaint",['retriveComplaints',"updateComplaint"]),

    }
  }
</script>
<style lang="scss" scoped>
.v-tooltip__content {

  background: rgba(0, 0, 0, 0) !important;
  pointer-events: auto !important;
}

</style>